<template>
    <div>
        <base-header base-title="Elastic Configuration"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <base-button
                                :button-text="'Re Index Elastic'"
                                :button-icon="'flaticon-refresh'"
                                :button-name="'post_elastic'"
                                :onButtonModalClicked="openForm"
                            ></base-button>
                            <base-button
                                :button-text="'Re Index Permission'"
                                :button-icon="'flaticon-refresh'"
                                :button-name="'post_permission'"
                                :onButtonModalClicked="postPermission"
                            ></base-button>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body table-responsive" v-if="isLoaded">
                    <data-tables :table="table" @checkbox="doCheck"></data-tables>
                </div>
                <div v-else class="m-auto p-5 d-flex flex-row h-100">
                    <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                    <h5 class="my-auto pl-5">Loading ...</h5>
                </div>
               <form-modal
                    v-if="showModal"
                    :show-modal.sync="showModal"
                    :modal-name="'form-modal-entities'"
                    :modal-data="entities"
                    :data-length="listEntities.length"
                ></form-modal>
            </div>
        </div>
    </div>
</template>

<script>
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import SweetAlertTemplate from "./../_general/SweetAlert";
const SweetAlert = Vue.extend(SweetAlertTemplate);
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import LoadSpinner from "./../_general/LoadSpinner";
import BlockUI from "../_js/BlockUI";
const blockUI = new BlockUI();
import BtnAction from "./_components/BtnAction";
import DataTables from "./../_table/DataTables";
import BaseButton from "./../_base/BaseButton";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const ElasticRepository = RepositoryFactory.get("elastic");
export default {
  components: {
    BaseHeader,
    BaseTitle,
    BtnAction,
    DataTables,
    BaseButton,
    LoadSpinner,
    FormModal: () => ({
      component: new Promise(function(resolve, reject) {
        setTimeout(function() {
          resolve(
            import(/* webpackChunkName: "modal-form-entities" */ "./_components/FormModal.vue")
          );
        }, 1000);
      }),
      loading: LoadSpinner
    })
  },
  data() {
    var vx = this;
    return {
      headTitle: "Elastic Search",
      isLoaded: false,
      table: {
        tableName: "table_selected_elastic",
        ordering: false,
        tableDataResult: [],
        tableColumnDefs: [
          {
            defaultContent: "-",
            targets: "_all"
          },
          {
            targets: 2,
            visible: false
          },
          {
            targets: 1,
            responsivePriority: 1
          }
        ],
        tableColumns: [
          {
            title: "Nama",
            data: "nama"
          },
          {
            title: "Check",
            createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
              let btnAction = Vue.extend(BtnAction);
              let instance = new btnAction({
                propsData: {
                  elasticVue: vx,
                  rowData: rowData
                }
              });
              instance.$mount();
              $(cell)
                .empty()
                .append(instance.$el);
            }
          },
          {
              title:"Actions"
          }
        ],
        tableFilter: [
          {
            title: "Nama",
            type: "text"
          },
          {
            title: "Check",
            type: "checkbox"
          },
          {
            title: "Actions",
            type: "actions"
          }
        ]
      },
      entities: [],
      listEntities:[],
      showModal: false
    };
  },
  methods: {
    openForm: function() {
      this.showModal = true;
    },
    addEntities: function(val) {
      this.entities = [...this.entities, ...val];
      this.reloadTable();
    },
    postPermission: function() {
      var vx = this;
      let instance = new SweetAlert().showConfirm({
        swalTitle: "Apakah Anda yakin?",
        swalText: "Anda akan melakukan reindex permission.",
        swalType: "warning",
        onConfirmButton: function() {
          blockUI.blockPage();
          vx.rePermission();
        },
        onCancelButton: function() {
          blockUI.unblockPage();
        }
      });
    },
    rePermission: function() {
      ElasticRepository.postPermission()
        .then(res => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Berhasil!",
            swalText: "Anda berhasil melakukan reindex permission.",
            swalType: "success",
            onConfirmButton: function() {
              blockUI.unblockPage();
            }
          });
        })
        .catch(res => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Gagal Reindex Permission.",
            swalType: "error",
            onConfirmButton: function() {
              blockUI.unblockPage();
            }
          });
        });
    },
    postElastic: function() {
      var vx = this;
      let instance = new SweetAlert().showConfirm({
        swalTitle: "Apakah Anda yakin?",
        swalText: "Anda akan melakukan reindex elastic",
        swalType: "warning",
        onConfirmButton: function() {
          blockUI.blockPage();
          vx.reIndex();
        },
        onCancelButton: function() {
          blockUI.unblockPage();
        }
      });
    },
    reIndex: function() {
      let param = "";
      if (this.entities.length > 0) {
        param = "?entities=";
        this.entities.forEach((x, index) => {
          if (index == 0) {
            param = param + x;
          } else {
            param = param + "%2C" + x;
          }
        });
      }
      ElasticRepository.postIndex(param)
        .then(res => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Berhasil!",
            swalText: "Anda berhasil melakukan reindex elastic.",
            swalType: "success",
            onConfirmButton: function() {
              blockUI.unblockPage();
            }
          });
        })
        .catch(res => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Gagal Reindex Elastic.",
            swalType: "error",
            onConfirmButton: function() {
              blockUI.unblockPage();
            }
          });
        });
    },
    onInit: function() {
      ElasticRepository.get()
        .then(response => {
          this.listEntities = response.data.map(x => {
            var temp = {
              nama: x
            };
            return temp;
          });
          if (this.isLoaded) {
            var datatable = $("#" + this.table.tableName).DataTable();
            datatable.clear();
            datatable.rows.add(this.listEntities);
            datatable.draw("full-hold");
            if (resolve !== undefined) {
              resolve(this.listEntities);
            }
          } else {
            this.table.tableDataResult = this.listEntities;
            this.isLoaded = true;
          }
        })
        .catch(error => {
          var msg = error.message ? error.message : error;
          this.showToastError("List Entities : " + msg);
        });
    },
    doCheck: function(val){
        this.entities = (val) ? this.listEntities.map(x => x.nama) : [];
    },
  },
  created() {
    this.onInit();
  }
};
</script>

<style>
</style>
