<template>
    <div>
        <base-header base-title="Chat User"></base-header>
        <!-- begin:: Content -->
        <div
            class="kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content"
        >
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <auto-refresh
                                :table-name="tableData.tableName"
                                :on-refresh="fetch"
                            ></auto-refresh
                            >&nbsp;&nbsp;
                            <template  v-if="checkPermission('ChatResource.POST.Room.user')"  >
                                <base-button-modal
                                    :button-text="modalText"
                                    :button-icon="modalIcon"
                                    :button-name="modalName"
                                    :onButtonModalClicked="showModal"
                                ></base-button-modal>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body" v-if="isLoaded">
                    <data-tables :table="table"></data-tables>
                </div>
                <div v-else class="m-auto p-5 d-flex flex-row h-100">
                    <div
                        class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"
                    ></div>
                    <h5 class="my-auto pl-5">Loading ...</h5>
                </div>
                <form-modal v-if="isShowModal" @event="fetch"></form-modal>
            </div>
        </div>
        <!-- end:: Content -->
    </div>
</template>

<script>
import grupRujukanEnum from "../../constants/grup-rujukan-enum";
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import BaseButtonModal from "./../_base/BaseButtonModal";
import AutoRefresh from "./../_general/AutoRefresh";
import BtnAction from "./_components/BtnAction";
import DataTables from "./../_table/DataTables";
import LoadSpinner from "./../_general/LoadSpinner";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const MasterRepository = RepositoryFactory.get("chatUser");
import { mapState, mapMutations, mapActions } from "vuex";

export default {
    components: {
        AutoRefresh,
        DataTables,
        BaseTitle,
        BaseHeader,
        BaseButtonModal,
        FormModal: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(
                            /* webpackChunkName: "modal-chat-user" */ "./_components/FormModal.vue"
                        )
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        })
    },
    data() {
        var vx = this;
        return {
            headTitle: "Chat User",
            modalText: "Tambah Chat User",
            modalIcon: "flaticon2-add-1",
            tableData: {
                tableName: "table_chat_user",
                tableDataResult: [],
                tableColumnDefs: [
                    {
                        defaultContent: "-",
                        targets: "_all"
                    },
                    {
                        targets: 2,
                        render: function(data, type, row, meta) {
                            return vx.$i18n.t("EnumStage." + data);
                        }
                    },
                    {
                        targets: -1,
                        responsivePriority: 1
                    }
                ],
                tableColumns: [
                    {
                        title: "ID",
                        data: "id"
                    },
                    {
                        title: "User Id",
                        data: "userId"
                    },
                    {
                        title: "Email",
                        data: "email"
                    },
                    {
                        title: "Nama",
                        data: "name"
                    },
                    {
                        title: "Password",
                        data: "password"
                    },
                    {
                        title: "Username",
                        data: "username"
                    },
                    {
                        title: "Rocket Chat Id",
                        data: "rocketChatId"
                    },
                    {
                        title: "Actions",
                        data: "id",
                        createdCell: function(
                            cell,
                            cellData,
                            rowData,
                            rowIndex,
                            colIndex
                        ) {
                            let btnAction = Vue.extend(BtnAction);
                            let instance = new btnAction({
                                propsData: {
                                    rowData: rowData,
                                    storeObject: vx.$store
                                }
                            });
                            instance.$mount();
                            $(cell)
                                .empty()
                                .append(instance.$el);
                        }
                    }
                ],
                tableFilter: [
                    {
                        title: "ID",
                        type: "text"
                    },
                    {
                        title: "User Id",
                        type: "text"
                    },
                    {
                        title: "Email",
                        type: "text"
                    },
                    {
                        title: "Nama",
                        type: "text"
                    },
                    {
                        title: "Password",
                        type: "text"
                    },
                    {
                        title: "Username",
                        type: "text"
                    },
                    {
                        title: "Rocket Chat Id",
                        type: "text"
                    },
                    {
                        title: "Actions",
                        type: "actions"
                    }
                ]
            }
        };
    },
    computed: {
        ...mapState("MasterStore", {
            repository: state => state.repository,
            isLoaded: state => state.isLoaded,
            table: state => state.table,
            isShowModal: state => state.isShowModal,
            dataEdit: state => state.dataEdit,
            modalName: state => state.modalName
        })
    },
    watch: {
        isShowModal: function(val) {
            if (!val) {
                this.setEditData(null);
            }
        }
    },
    methods: {
        ...mapActions({
            fetch: "MasterStore/fetch"
        }),
        ...mapMutations({
            setupTable: "MasterStore/SETUP_TABLE",
            showModal: "MasterStore/SHOW_MODAL",
            setRepository: "MasterStore/SET_REPOSITORY",
            setModal: "MasterStore/SET_MODAL",
            setEditData: "MasterStore/SET_EDIT_DATA",
            setLoaded: "MasterStore/SET_LOADED"
        }),
        checkPermission: function(method) {
            return checkPermission.isCanAccess(method);
        }
    },
    created() {
        this.setLoaded(false);
        this.setRepository(MasterRepository);
        this.setModal("modal_chat_user");
    },
    mounted() {
        this.setupTable(this.tableData);
        this.fetch();
    }
};
</script>
