<template>
    <div>
        <base-header base-title="Transaksi Referral"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="'Transaksi Referral'"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                             <auto-refresh
                                v-if="showAutoRefresh"
                                ref="auto"
                                :table-name="tableName"
                                :on-refresh="fetch"
                                :auto-refresh-state="true"
                            ></auto-refresh>&nbsp;&nbsp;
                            <sound-notifiction ref="notif"></sound-notifiction>&nbsp;&nbsp;
                            <template v-if="checkPermission('TransaksiReferralResource.POST.TransaksiReferral')">
                             <base-button
                                :button-text="'Tambah Transaksi Referral'"
                                :button-icon="'flaticon2-add-1'"
                                :button-name="'modal_transaksiReferral'"
                                :onButtonModalClicked="openForm"
                            ></base-button>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <v-server-table :columns="columns" :options="options" ref="table">
                        <div slot="action" slot-scope="{ row }">
                            <btn-action :row-data="row" :transaksi-vue="transaksiVue"></btn-action>
                        </div>
                    </v-server-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import jejaks from "./../../services/jejak.service";
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import AutoRefresh from "./../_general/AutoRefresh";
import BaseButton from "./../_base/BaseButton";
import DataTables from "./../_table/DataTables";
import BtnAction from "./_components/BtnAction";
import LoadSpinner from "./../_general/LoadSpinner";
import DateRangePicker from "./../_general/CustomDateRangePicker";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
import JejakEnum from "./../../constants/jejak-enum";
const TransaksiReferralRepository = RepositoryFactory.get("transaksiReferral");
import TransaksiReferral from "./../../model/transaksi-referral-model";
import BlockUI from "../_js/BlockUI";
import DateRange from './../../constants/date-range-enum';
const blockUI = new BlockUI();
import SoundNotifiction from './../_general/SoundNotification';
import { isEmpty } from 'util';

export default {
  components: {
    BaseHeader,
    BaseTitle,
    AutoRefresh,
    BaseButton,
    DataTables,
    LoadSpinner,
    BtnAction,
    DateRangePicker,
    SoundNotifiction
  },
  data() {
    var vx = this;
    return {
        isLoaded: false,
        isShowModal: false,
        tableName: "table_transaksiReferral",
        maxNotifDate: moment(DateRange.MIN_DATE),
        modeAutoRefresh: null,
        showAutoRefresh: true,
        transaksiVue: vx,
        totalCount: null,
        isFirstReload: true,
        modeSearch: true,
        columns: ['id','createdDateRange','nama','tanggalLahirRange','alamat','telepon', 'tujuanRujukan', 'jenisPenjamin', 'perujukNama','jejakTerakhirStage', 'jejakTerakhirTimestampRange', 'nomorRegistrasi','action'],
        options: {
            texts: {
                filterBy: 'Cari',
            },
            filterable:['createdDateRange','nama','tanggalLahirRange','alamat','telepon', 'tujuanRujukan', 'jenisPenjamin', 'perujukNama','jejakTerakhirStage', 'jejakTerakhirTimestampRange', 'nomorRegistrasi'],
            headings: {
                id: 'Id',
                createdDateRange: 'Tanggal Daftar',
                nama: 'Nama',
                tanggalLahirRange: 'Tanggal Lahir',
                alamat: 'Alamat',
                telepon: 'Telepon',
                tujuanRujukan: 'Tujuan Rujukan',
                jenisPenjamin: 'Jenis Penjamin',
                perujukNama: 'Perujuk',
                jejakTerakhirStage: 'Status',
                jejakTerakhirTimestampRange: 'Tanggal Update Status',
                nomorRegistrasi: 'Nomor Registrasi',
                action: 'Actions'
            },
            dateColumns: ['createdDateRange', 'jejakTerakhirTimestampRange', 'tanggalLahirRange' ],
            toMomentFormat: 'YYYY-MM-DD',
            initFilters:(vx.$route.query.jejak) ? {
                jejakTerakhirTimestampRange:{
                    start: moment(vx.$route.query.startDate),
                    end: moment(vx.$route.query.endDate)
                },
                jejakTerakhirStage: vx.$route.query.jejak
            } : {},
            requestFunction: function (data) {
                vx.modeAutoRefresh = vx.$refs.auto.data_auto_refresh
                vx.showAutoRefresh = (vx.modeAutoRefresh) ? false : true;
                let params = {};
                params.page = data.page-1;
                params.size = data.limit;
                params.sort = ["jejakTerakhirTimestamp,desc"];
                helpers.filterColor(data.query, vx.options.filterable);
                if(_.isEmpty(data.query)){
                    vx.modeSearch = false;
                    params.jejakTerakhirStagesIn = jejaks.viewJejaks();
                    return TransaksiReferralRepository.fetch(params).catch(function (e) {
                        vx.showToastError('Error Get Data');
                        this.dispatch('error', e);
                    }.bind(this));
                } else {
                    vx.modeSearch = true;
                    params.query = helpers.filterTable(data.query);
                    // console.log('ahoy masuk sini nih', params);
                    params.enumStages = jejaks.viewJejaks();
                    return TransaksiReferralRepository.search(params).catch(function (e) {
                        vx.showToastError('Error Get Data');
                        this.dispatch('error', e);
                    }.bind(this));
                    // return TransaksiReferralRepository.search(params).then(res => {
                    //     vx.totalCount = res.headers['x-total-count'];
                    //     var paramIds = {
                    //         'id.in': res.data.map(x => x.id).join(','),
                    //         size: data.limit,
                    //         sort: ["jejakTerakhirTimestamp,desc"]
                    //     };
                    //     Promise.resolve();
                    //     if(res.data.length == 0){
                    //         return TransaksiReferralRepository.fetchEmpty();
                    //     }
                    //     return TransaksiReferralRepository.fetch(paramIds);
                    // }).catch(function (e) {
                    //     vx.showToastError('Error Get Data');
                    //     this.dispatch('error', e);
                    // }.bind(this));
                }
            },
            responseAdapter: (resp) => {
                var call = false;
                var listNotification = jejaks.listNotification();
                const data = (!vx.isFirstReload) ? resp.data.map(x => {
                    x.isNewData = (listNotification.includes(x.jejakTerakhirStage)) ? moment(x.jejakTerakhirTimestamp).isAfter(vx.maxNotifDate) : false;
                    call = (x.isNewData) ? true : call;
                    return x;
                }): resp.data;
                var currentMaxDate = (data.length > 0) ? moment.max(data.filter(y => listNotification.includes(y.jejakTerakhirStage) ).map(x => moment(x.jejakTerakhirTimestamp))) : moment(DateRange.MIN_DATE);
                vx.maxNotifDate =  (moment(vx.maxNotifDate).isAfter(currentMaxDate)) ?  vx.maxNotifDate : currentMaxDate;
                vx.isFirstReload = false;
                vx.showAutoRefresh = true;
                if(call){
                    vx.$refs.notif.ring();
                }
                return {
                    data: data,
                    count: resp.headers['x-total-count']
                }
            },
            rowClassCallback(row) {
                return  (row.isNewData) ? 'highlight':'';
            },
            filterByColumn: true,
            sortable:[],
            listColumns: {
                jejakTerakhirStage: jejaks.viewJejaks().map(x => {return {id:x, text: vx.$i18n.t("EnumStage." + x) }})
            },
            templates: {
                createdDateRange(h, row) {
                    return moment(row.createdDate).add(7, "hours").format("DD-MMM-YYYY");
                },
                tanggalLahirRange(h, row) {
                    return moment(row.tanggalLahir).format("DD-MMM-YYYY");
                },
                tujuanRujukan(h, row){
                    return row.tujuanRujukan ? row.tujuanRujukan.nama : null;
                },
                jenisPenjamin(h, row){
                    return row.jenisPenjamin ? row.jenisPenjamin.nama : null;
                },
                perujukNama(h, row){
                    if (row.perujuk == null) {
                        return "";
                    } else {
                        let fullname = [];
                        fullname =
                        row.perujuk.firstName == null
                            ? fullname
                            : [...fullname, row.perujuk.firstName];
                        fullname =
                        row.perujuk.lastName == null
                            ? fullname
                            : [...fullname, row.perujuk.lastName];
                        return fullname.join(" ");
                    }
                },
                jejakTerakhirStage(h, row){
                    return vx.$i18n.t("EnumStage." + row.jejakTerakhirStage);
                },
                jejakTerakhirTimestampRange(h, row) {
                    return moment(row.jejakTerakhirTimestamp).add(7, "hours").format("DD-MMM-YYYY");
                },
            }
        }
    };
  },
  watch: {

  },
  methods: {
        openView: function(data) {
            this.$router.push({
                name: "transaksi-referral-view",
                params: { id: data.id }
            });
        },
        openForm: function(data = new TransaksiReferral()) {
            if (data.id) {
                this.$router.push({
                name: "transaksi-referral-edit",
                params: { id: data.id }
                });
            } else {
                this.$router.push({ name: "transaksi-referral-add" });
            }
        },
        getSearch: function(params){
            return Promise.resolve(TransaksiReferralRepository.search(params));
        },
        checkPermission: function(method) {
            return checkPermission.isCanAccess(method);
        },
        showToastError: function(message) {
            toastr.error(message);
        },
        fetch: function(resolve){
            // console.log(this.$refs, 'ini this', resolve);
            this.$refs.table.getData();
            if(resolve != undefined){
                resolve();
            }
        },
  },
  created() {
  }

};
</script>

<style>
</style>
