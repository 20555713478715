<template>
    <div class="text-center">
        <template v-if="checkPermission('TransaksiReferralResource.GET.TransaksiReferral.id')">
             <button
                    @click="viewTransaksi()"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md"
                    title="View"
                >
            <i class="la la-eye"></i>
            </button>
        </template>
        <template v-if="checkPermission('TransaksiReferralResource.PUT.TransaksiReferral')">
        <button
            @click="editTransaksi()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Edit"
        >
            <i class="la la-pencil-square"></i>
        </button>
        </template>
        <template v-if="checkPermission('TransaksiReferralResource.DELETE.TransaksiReferral.id')">
        <button
            @click="deleteTransaksi()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Hapus"
        >
            <i class="la la-trash"></i>
        </button>
        </template>
    </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const TransaksiReferralRepository = RepositoryFactory.get("transaksiReferral");
const checkPermission = new CheckPermission();
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
    props: {
        transaksiVue: { type: Object },
        rowData: { type: Object }
    },
    methods: {
        deleteTransaksi() {
            var vx = this;
            let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data Transaksi akan terhapus.",
                swalType: "warning",
                onConfirmButton: function () {
                    blockUI.blockPage();
                    vx.delete();
                },
                onCancelButton: function () {
                    blockUI.unblockPage();
                }
            });
        },
        async delete() {
            var vx = this;

            await TransaksiReferralRepository.delete(this.rowData.id)
                .then(response => {
                    new Promise((resolve, reject) => {
                        vx.transaksiVue.fetch(resolve, reject);
                    }).then((response) => {
                        let instance = new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Data Transaksi berhasil terhapus.",
                        swalType: "success",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "Data Transaksi gagal terhapus.",
                        swalType: "error",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                    });
                });
            },
        editTransaksi() {
           this.transaksiVue.openForm(this.rowData);
        },
        viewTransaksi() {
            this.transaksiVue.openView(this.rowData);
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        }
    }
}
</script>

<style>

</style>
