<template>
    <div class="text-center">
       <label class="kt-checkbox">
           <input class="chkKelas"
                type="checkbox"
                :value="rowData.nama"
                v-model="elasticVue.entities"
                name="chkbox"
            />
            &nbsp; <span></span>
        </label>
    </div>
</template>

<script>
export default {
    props: {
        elasticVue: { type: Object },
        rowData: { type: Object },
    },
}
</script>

<style>

</style>
